import classNames from 'classnames';
import Avatar from 'components/base/Avatar';
import Scrollbar from 'components/base/Scrollbar';
import Unicon from 'components/base/Unicon';
import { UilInvoice } from '@iconscout/react-unicons';

import FeatherIcon from 'feather-icons-react';
import useAPI from 'hooks/useAPI';
import useAuth from 'hooks/useAuth';
import useToaster from 'hooks/useToaster';
import { useState } from 'react';
import { Card, Dropdown, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ProfileDropdownMenu = ({ className }: { className?: string }) => {
  const { logout, user } = useAuth();
  const { createStripePortalLink } = useAPI();
  const toaster = useToaster();

  const [navItems] = useState<{ label: string; icon: string }[]>([
    // {
    //   label: 'Profile',
    //   icon: 'user'
    // },
    // {
    //   label: 'Dashboard',
    //   icon: 'pie-chart'
    // },
    // {
    //   label: 'Posts & Activity',
    //   icon: 'lock'
    // },
    // {
    //   label: 'Settings & Privacy ',
    //   icon: 'settings'
    // },
    // {
    //   label: 'Help Center',
    //   icon: 'help-circle'
    // },
    // {
    //   label: 'Language',
    //   icon: 'globe'
    // }
  ]);

  const handleClickInvoice = async () => {
    toaster.info('Redirection en cours...');

    createStripePortalLink()
      .then(url => {
        window.location.replace(url);
      })
      .catch(() => {
        toaster.error(
          "Échec de l'ouverture de votre espace client. Merci de contacter le support."
        );
      });
  };

  return (
    <Dropdown.Menu
      align="end"
      className={classNames(
        className,
        'navbar-top-dropdown-menu navbar-dropdown-caret py-0 dropdown-profile shadow border border-300'
      )}
    >
      <Card className="position-relative border-0">
        <Card.Body className="p-0">
          <div className="d-flex flex-column align-items-center justify-content-center gap-2 pt-4 pb-3">
            <Avatar variant="name" size="xl">
              {user?.displayName?.substring(0, 2)}
            </Avatar>
            <h6 className="text-black">
              {user?.firstname} {user?.lastname}
            </h6>
            <div>{user?.email}</div>
          </div>
          {/* <div className="mb-3 mx-3">
            <Form.Control
              type="text"
              placeholder="Update your status"
              size="sm"
            />
          </div> */}
          {/* <div style={{ height: '10rem' }}> */}
          <div style={{ height: '3rem' }}>
            <Scrollbar>
              <Nav className="nav flex-column mb-2 pb-1">
                {navItems.map(item => (
                  <Nav.Item key={item.label}>
                    <Nav.Link href="#!" className="px-3">
                      <FeatherIcon
                        icon={item.icon}
                        size={16}
                        className="me-2 text-900"
                      />
                      <span className="text-1000">{item.label}</span>
                    </Nav.Link>
                  </Nav.Item>
                ))}
                <Nav.Item key="Facturation" onClick={handleClickInvoice}>
                  <Nav.Link disabled href="#!" className="px-3">
                    <Unicon
                      icon={UilInvoice}
                      size={16}
                      className="me-2 text-900"
                    />
                    <span className="text-1000">Abonnement</span>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Scrollbar>
          </div>
        </Card.Body>
        <Card.Footer className="p-0 border-top">
          {/* <Nav className="nav flex-column my-3">
            <Nav.Item>
              <Nav.Link href="#!" className="px-3">
                <FeatherIcon
                  icon="user-plus"
                  size={16}
                  className="me-2 text-900"
                />
                <span>Add another account</span>
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <hr /> */}
          <div className="px-3 pt-2">
            <Link
              onClick={logout}
              to="#!"
              className="btn btn-krakken-danger d-flex flex-center w-100"
            >
              <FeatherIcon icon="log-out" className="me-2" size={16} />
              Se déconnecter
            </Link>
          </div>
          <div className="my-2 text-center fw-bold fs-10 text-600">
            <Link className="text-600 me-1" to="/privacy">
              Politique de confidentialité
            </Link>
            •
            <Link className="text-600 mx-1" to="/cgv">
              CGV
            </Link>
            •
            <Link className="text-600 ms-1" to="/cookies">
              Gestion des cookies
            </Link>
          </div>
        </Card.Footer>
      </Card>
    </Dropdown.Menu>
  );
};

export default ProfileDropdownMenu;
