// form
import { useFormContext, Controller } from 'react-hook-form';
import { Form, FormControlProps } from 'react-bootstrap';

// ----------------------------------------------------------------------

type IProps = {
  name: string;
};

type Props = IProps & FormControlProps;

export default function RHFControl({ name, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          <Form.Control
            id={name}
            {...field}
            value={
              typeof field.value === 'number' && field.value === 0
                ? ''
                : field.value
            }
            {...other}
            isInvalid={Boolean(error?.message)}
          />
          <Form.Control.Feedback type="invalid">
            {error?.message}
          </Form.Control.Feedback>
        </>
        // <TextField
        //   {...field}
        //   fullWidth
        //   value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
        //   error={!!error}
        //   helperText={error?.message}
        //   {...other}
        // />
      )}
    />
  );
}
