import { faExternalLink, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from 'components/base/Button';
// import AuthSocialButtons from 'components/common/AuthSocialButtons';
import { FormProvider, RHFControl } from 'components/hook-form';
import { FORMATION_URL } from 'config';
import useAuth from 'hooks/useAuth';
import useToaster from 'hooks/useToaster';
import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

const SignInSchema = yup
  .object({
    email: yup
      .string()
      .email('Merci de renseigner un email valide')
      .required('Champs requis')
  })
  .required();

interface FormValuesProps {
  email: string;
}

// eslint-disable-next-line no-empty-pattern
const SignInForm = ({}: { layout: 'simple' | 'card' | 'split' }) => {
  // hooks
  const toaster = useToaster();
  const { sendSignInLinkToEmail } = useAuth();

  // stats
  const [isSent, setIsSent] = useState(false);

  // form hooks
  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(SignInSchema),
    defaultValues: {
      email: ''
    }
  });
  const {
    handleSubmit,
    formState: { isSubmitted, isValid, isSubmitting }
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    console.log(JSON.stringify(data, null, 2));
    const { email } = data;

    try {
      window.localStorage.setItem('emailForSignIn', email);
      await sendSignInLinkToEmail(email);

      // toast('Vous êtes connecté');
      toaster.success('Lien de connexion envoyé');
      setIsSent(true);
    } catch (err: any) {
      console.error(err);
      console.error(JSON.stringify(err));
      toaster.error('firebase.' + err.code);
    }
  };

  return (
    <>
      <div className="text-center mb-7">
        <h3 className="text-1000">Accéder à votre compte Krakken</h3>
      </div>
      {!isSent && (
        <div className="text-center">
          <p className="text-700">
            Krakken est une plateforme construite en collaboration avec la
            formation Marketplace de FrenchStartupper.
          </p>
          <p className="text-700">
            L'accès est réservé aux participants de la formation.
          </p>
          <a href={FORMATION_URL} target="_blank" rel="noreferrer">
            Voir la formation <FontAwesomeIcon icon={faExternalLink} />
          </a>
          {/* <AuthSocialButtons title="Sign in" /> */}
          {/* <div className="position-relative">
            <hr className="bg-200 mt-5 mb-4" />
            <div className="divider-content-center">ou par email</div>
          </div> */}
          <FormProvider
            methods={methods}
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            validated={isSubmitted && isValid}
          >
            <Form.Group className="mb-3 text-start">
              <Form.Label htmlFor="email">Email</Form.Label>
              <div className="form-icon-container">
                <RHFControl
                  name="email"
                  type="email"
                  className="form-icon-input"
                  placeholder="name@example.com"
                />
                <FontAwesomeIcon
                  icon={faUser}
                  className="text-900 fs-9 form-icon"
                />
              </div>
            </Form.Group>
            {/* <Form.Group className="mb-3 text-start">
              <Form.Label htmlFor="password">Password</Form.Label>
              <div className="form-icon-container">
                <Form.Control
                  id="password"
                  type="password"
                  className="form-icon-input"
                  placeholder="Password"
                />
                <FontAwesomeIcon icon={faKey} className="text-900 fs-9 form-icon" />
              </div>
            </Form.Group> */}
            {/* <Row className="flex-between-center mb-7">
              <Col xs="auto">
                <Form.Check type="checkbox" className="mb-0">
                  <Form.Check.Input
                    type="checkbox"
                    name="remember-me"
                    id="remember-me"
                    defaultChecked
                  />
                  <Form.Check.Label htmlFor="remember-me" className="mb-0">
                    Remember me
                  </Form.Check.Label>
                </Form.Check>
              </Col>
              <Col xs="auto">
                <Link
                  to={`/pages/authentication/${layout}/forgot-password`}
                  className="fs-9 fw-semi-bold"
                >
                  Forgot Password?
                </Link>
              </Col>
            </Row> */}
            <Button
              loading={isSubmitting}
              variant="primary"
              className="w-100 mb-3"
              type="submit"
            >
              Se connecter
            </Button>
          </FormProvider>
          {/* <div className="text-center">
            <Link
              to={`/pages/authentication/${layout}/sign-up`}
              className="fs-9 fw-bold"
            >
              Create an account
            </Link>
          </div> */}
        </div>
      )}
      {isSent && (
        <div className="text-center mb-7">
          <p className="text-800">
            Un lien de connexion vient de vous être envoyé par email.
          </p>
          <p className="text-700">Vérifier vos spams</p>
        </div>
      )}
    </>
  );
};

export default SignInForm;
