import useAuth from 'hooks/useAuth';
import useToaster from 'hooks/useToaster';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

// eslint-disable-next-line no-empty-pattern
const SignInLinkValidation = ({}: { layout?: 'simple' | 'card' | 'split' }) => {
  const toaster = useToaster();
  const { isSignInWithEmailLink, signInWithEmailLink } = useAuth();
  const navigate = useNavigate();

  useEffectOnce(() => {
    const email = window.localStorage.getItem('emailForSignIn');
    const is = isSignInWithEmailLink();
    if (!is || !email) {
      navigate('/auth/sign-in');
    } else {
      signInWithEmailLink(email, window.location.href)
        .then(result => {
          // Clear email from storage.
          window.localStorage.removeItem('emailForSignIn');
          console.debug(result);
          toaster.success('Connexion réussi !');
          // navigate('/');
        })
        .catch(error => {
          console.error(error);
          toaster.error(error.code);
        });
    }
  });

  return (
    <>
      <div className="text-center mb-7">
        <h3 className="text-1000">Connexion en cours</h3>
        <p className="text-700">Merci de patienter quelques secondes</p>
        <Spinner animation="grow" />
      </div>
    </>
  );
};

export default SignInLinkValidation;
