import DefaultLandingNavbar from 'components/navbars/default-landing-navbar/DefaultLandingNavbar';
import useSettingsMountEffect from 'hooks/useSettingsMountEffect';

const CGVPage = () => {
  useSettingsMountEffect({
    disableNavigationType: true,
    disableHorizontalNavbarAppearance: true,
    disableVerticalNavbarAppearance: true,
    disableHorizontalNavbarShape: true
  });

  return (
    <div className="bg-white">
      <DefaultLandingNavbar />
      <section className="container-small px-lg-7 px-xxl-3">
        <h1>Conditions générales de vente</h1>
        <p>
          En accédant à ce site web, nous supposons que vous acceptez ces
          conditions générales. Nous nous réservons le droit, et à notre seule
          discrétion, de réviser ces conditions générales de temps en temps.
          Veuillez consulter régulièrement cette page pour vous assurer que vous
          êtes à jour. La terminologie suivante s'applique aux présentes
          conditions générales, aux politiques de confidentialité, ainsi qu'à
          tous les accords : "Client", "Vous" et "Votre" font référence à vous,
          la personne qui se connecte sur ce site web et qui respecte les
          conditions générales de la société. "Nous", "Notre", "Nos" et "Notre",
          font référence à notre société. "Partie", "Parties" ou "Nous", désigne
          à la fois le Client et nous-mêmes.
        </p>
      </section>
    </div>
  );
};

export default CGVPage;
