import Button from 'components/base/Button';
import AuthSocialButtons from 'components/common/AuthSocialButtons';
import { FormProvider, RHFCheckbox, RHFControl } from 'components/hook-form';
import { Col, Form, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
// Forms
import { yupResolver } from '@hookform/resolvers/yup';
import useAuth from 'hooks/useAuth';
import useToaster from 'hooks/useToaster';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

const SignUpSchema = yup
  .object({
    firstname: yup.string().required('Champs requis'),
    lastname: yup.string().required('Champs requis'),
    email: yup
      .string()
      .email('Merci de renseigner un email valide')
      .required('Champs requis'),
    password: yup
      .string()
      .required('Champs requis')
      .max(255, 'Maximum 255 caractères')
      .matches(
        // eslint-disable-next-line no-useless-escape
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!?\.@#\$%\^&\*])(?=.{8,})/,
        'Le mot de passe doit contenir au moins un caractère spécial'
      ),
    confirmPassword: yup
      .string()
      .required('Champs requis')
      .oneOf(
        [yup.ref('password')],
        'Les mots de passes doivent être identiques'
      ),
    termsService: yup.bool().oneOf([true], 'Vous').required()
  })
  .required();

interface FormValuesProps {
  lastname: string;
  firstname: string;
  email: string;
  password: string;
  confirmPassword: string;
  termsService: boolean;
}

const SignUpForm = ({ layout }: { layout: 'simple' | 'card' | 'split' }) => {
  const toaster = useToaster();
  const { register } = useAuth();
  const navigate = useNavigate();

  // form hooks
  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(SignUpSchema),
    defaultValues: {
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      confirmPassword: '',
      termsService: true
    }
  });
  const {
    handleSubmit,
    formState: { isSubmitted, isValid, isSubmitting }
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    console.log(JSON.stringify(data, null, 2));
    const { email, firstname, lastname, password } = data;

    try {
      await register(email, password, firstname, lastname);

      navigate('/');
    } catch (err: any) {
      console.error(err);
      toaster.error('firebase.' + err.code);
    }
  };

  return (
    <>
      <div className="text-center mb-7">
        <h3 className="text-1000">Creer un compte</h3>
        <p className="text-700">Creer votre compte aujourd'hui</p>
      </div>
      <AuthSocialButtons title="Sign up" />
      <div className="position-relative mt-4">
        <hr className="bg-200" />
        <div className="divider-content-center">ou par email</div>
      </div>
      <FormProvider
        methods={methods}
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        validated={isSubmitted && isValid}
      >
        <Row className="g-3 mb-3">
          <Col sm={layout === 'card' ? 12 : 6} lg={6}>
            <Form.Group className="text-start">
              <Form.Label htmlFor="firstname">Prénom</Form.Label>
              <RHFControl
                name="firstname"
                id="firstname"
                type="text"
                placeholder="Prénom"
              />
            </Form.Group>
          </Col>
          <Col sm={layout === 'card' ? 12 : 6} lg={6}>
            <Form.Group className="text-start">
              <Form.Label htmlFor="lastname">Nom</Form.Label>
              <RHFControl
                name="lastname"
                id="lastname"
                type="text"
                placeholder="Nom"
              />
            </Form.Group>
          </Col>
        </Row>
        {/* <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="email">Email</Form.Label>
          <Form.Control
            id="email"
            type="email"
            required
            placeholder="name@example.com"
          />
        </Form.Group> */}
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="email">Email</Form.Label>
          <RHFControl
            name="email"
            id="email"
            type="email"
            placeholder="name@example.com"
          />
        </Form.Group>
        <Row className="g-3 mb-3">
          <Col sm={layout === 'card' ? 12 : 6} lg={6}>
            <Form.Group>
              <Form.Label htmlFor="password">Mot de passe</Form.Label>
              <RHFControl
                name="password"
                id="password"
                type="password"
                placeholder="Mot de passe"
              />
            </Form.Group>
          </Col>
          <Col sm={layout === 'card' ? 12 : 6} lg={6}>
            <Form.Group>
              <Form.Label htmlFor="confirmPassword">Confirmation</Form.Label>
              <RHFControl
                name="confirmPassword"
                id="confirmPassword"
                type="password"
                placeholder="Mot de passe"
              />
            </Form.Group>
          </Col>
        </Row>
        <RHFCheckbox name="termsService">
          <Form.Check.Label htmlFor="termsService" className="fs-9 text-none">
            J'accepte les{' '}
            <Link to="#!">Conditions Générales d'Utilisations </Link> et{' '}
            <Link to="#!">la Politique de Confidentialité</Link>
          </Form.Check.Label>
        </RHFCheckbox>
        <Button
          loading={isSubmitting}
          variant="primary"
          className="w-100 mb-3"
          type="submit"
        >
          Créer un compte
        </Button>
        <div className="text-center fs-9">
          J'ai déja un compte ?{' '}
          <Link to={`/auth/sign-in`} className="fw-bold">
            Se connecter
          </Link>
        </div>
      </FormProvider>
    </>
  );
};

export default SignUpForm;
