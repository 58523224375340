// import Pricings from 'components/pricing/Pricings';
import useAuth from 'hooks/useAuth';
import { Col, Row } from 'react-bootstrap';

const HomePage = () => {
  const { user } = useAuth();

  return (
    <>
      <div className="pb-5">
        <Row className="g-4">
          <Col xs={12} xxl={6}>
            <div className="mb-8">
              <h2 className="mb-2">{process.env.REACT_APP_TITLE}</h2>
              <h5 className="text-700 fw-semi-bold">
                Trouvez facilement des produits gagnants !
              </h5>
            </div>
          </Col>
        </Row>
        {user?.authorization === 'granted' && (
          <Row className="justify-content-center">
            <Col xs="auto">
              <iframe
                width="854"
                height="480"
                src="https://d1yei2z3i6k35z.cloudfront.net/1078502/66e7e855a8cb1_Krakkenexplications.mp4"
              />
            </Col>
          </Row>
        )}
        {/* {user?.authorization === 'limited' && (
          <Row className="justify-content-center">
            <Col xxl>
              <Pricings />
            </Col>
          </Row>
        )} */}
      </div>
    </>
  );
};

export default HomePage;
