import { useContext } from 'react';
//
import { AuthContext } from '../providers/FirebaseProvider';

// ----------------------------------------------------------------------

const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context)
    throw new Error('Auth context must be use inside FirebaseProvider');

  return context;
};

export default useAuth;
