import i18n from 'i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';
import { initReactI18next } from 'react-i18next';
// config
import { defaultLang } from '../config';
//
import frLocales from './fr';

// ----------------------------------------------------------------------

let lng = defaultLang.value;

if (typeof window !== 'undefined') {
  lng = localStorage.getItem('i18nextLng') || defaultLang.value;
}

i18n
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      fr: { translations: frLocales }
    },
    lng,
    fallbackLng: defaultLang.value,
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
      format: function (value, formatting) {
        if (value instanceof Date) return moment(value).format(formatting);
        return value.toString();
      }
    }
  });

export default i18n;
