import { useTranslation } from 'react-i18next';
import { ToastContent, toast } from 'react-toastify';

const useToaster = () => {
  const { t } = useTranslation();

  const error = (content: ToastContent<unknown>) => {
    toast(typeof content === 'string' ? t(content) : content, {
      type: 'error'
    });
  };
  const info = (content: ToastContent<unknown>) => {
    toast(typeof content === 'string' ? t(content) : content, {
      type: 'info'
    });
  };
  const success = (content: ToastContent<unknown>) => {
    toast(typeof content === 'string' ? t(content) : content, {
      type: 'success'
    });
  };

  return { error, info, success };
};

export default useToaster;
