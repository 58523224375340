/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ColumnDef,
  InitialTableState,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table';
import { PropsWithChildren, useEffect, useState } from 'react';

import IndeterminateCheckbox from 'components/base/IndeterminateCheckbox';
import {
  Query,
  QueryDocumentSnapshot,
  endBefore,
  limit,
  orderBy,
  query,
  startAfter
} from 'firebase/firestore';
import { useCollectionOnce } from 'react-firebase-hooks/firestore';
import { DocumentDataIdentifiable } from './useFirestore';
import { useDebounce, useLogger, usePrevious } from 'react-use';
import useCollectionCountOnce from './useCollectionCount';
import * as _ from 'lodash';
// import { collection } from 'firebase/firestore';
// Assume other imports remain the same

// Adjusted UseFirestoreTableProps to remove data array
interface UseFirestoreTableProps<T> {
  columns: ColumnDef<T>[];
  selection?: boolean;
  sortable?: boolean;
  pagination?: boolean;
  pageSize?: number;
  initialState: Partial<InitialTableState> &
    Required<Pick<InitialTableState, 'sorting'>>;
  firestoreQuery: {
    collection: Query<any>;
    conditions: any[];
  };
  // firestoreQuery?: any; // Optional: for custom Firestore queries
}

const selectionColumn = {
  id: 'select',
  accessorKey: '',
  header: ({ table }: any) => (
    <IndeterminateCheckbox
      className="form-check fs-8 mb-0"
      {...{
        checked: table.getIsAllRowsSelected(),
        indeterminate: table.getIsSomeRowsSelected(),
        onChange: table.getToggleAllRowsSelectedHandler()
      }}
    />
  ),
  cell: ({ row }: any) => (
    <IndeterminateCheckbox
      className="form-check fs-8 mb-0"
      {...{
        checked: row.getIsSelected(),
        disabled: !row.getCanSelect(),
        indeterminate: row.getIsSomeSelected(),
        onChange: row.getToggleSelectedHandler()
      }}
    />
  ),
  meta: {
    headerProps: { style: { width: '30px' } }
  }
};

const useFirestoreTable = <T,>({
  columns,
  selection,
  sortable,
  pageSize = 10,
  initialState,
  firestoreQuery: { conditions, collection }
}: PropsWithChildren<UseFirestoreTableProps<DocumentDataIdentifiable<T>>>) => {
  const [data, setData] = useState<DocumentDataIdentifiable<T>[]>([]);
  const [fQuery, setQuery] = useState<Query<any>>();
  const [countQuery, setCountQuery] = useState<Query<any>>();
  const [lastRef, setLastRef] = useState<QueryDocumentSnapshot<any>>();

  const [count] = useCollectionCountOnce(countQuery);

  useEffect(() => {
    console.log('count / pageSize', count, pageSize, (count || 1) / pageSize);
  }, [count]);

  const state = {
    pagination: { pageSize: pageSize || data.length },
    ...initialState
  };

  const table = useReactTable<DocumentDataIdentifiable<T>>({
    data,
    columns: selection ? [selectionColumn, ...columns] : columns,
    enableSorting: sortable,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: state,
    // onPaginationChange: old => {
    //   console.debug('onPaginationChange', old, pagination);
    // },
    manualPagination: true,
    pageCount: count && pageSize ? _.ceil(count / pageSize) : undefined
  });
  const { sorting, pagination } = table.getState();

  const previousPagination = usePrevious(pagination);

  useDebounce(
    () => {
      // console.debug('useDebounce');
      const conditionPage = [];

      if (sorting?.at(0))
        conditionPage.push(
          orderBy(sorting[0].id, sorting[0].desc ? 'desc' : 'asc')
        );

      if (pagination) conditionPage.push(limit(pagination.pageSize));

      const docs = value?.docs;
      if (
        previousPagination &&
        previousPagination !== pagination &&
        docs?.length
      ) {
        // Next page
        if (previousPagination.pageIndex < pagination.pageIndex) {
          const startRef = docs[docs.length - 1];
          conditionPage.push(startAfter(startRef));
        } else {
          const endRef = docs.length ? docs[0] : lastRef;
          conditionPage.push(endBefore(endRef));
        }
      }
      setQuery(query(collection, ...conditions, ...conditionPage));
      setCountQuery(query(collection, ...conditions));
    },
    1000,
    // when you add collection, the function is recursively called
    [sorting, pagination, conditions]
  );
  useLogger('sorting', sorting);

  // Use useCollectionData hook to fetch data from Firestore
  const [value, loading, error] = useCollectionOnce<T>(fQuery);

  useEffect(() => {
    if (error) console.error(error);
  }, [error]);

  // Set data
  useEffect(() => {
    setData(
      value?.docs.map(v => ({ ...v.data(), id: v.id, ref: v.ref })) || []
    );

    if (value?.docs.length) {
      const lastRef = value?.docs[value?.docs.length - 1];
      setLastRef(lastRef);
    }
  }, [value]);

  // Update query
  // useEffect(() => {
  //   const conditionPage = [];

  //   if (pagination) conditionPage.push(limit(pagination.pageSize));

  //   // if (previousPagination && previousValue) {
  //   //   // Next page
  //   //   if (previousPagination.pageIndex < pagination.pageIndex) {
  //   //     conditions.push(startAt(previousValue[previousValue.length - 1]));
  //   //   } else {
  //   //     //
  //   //   }
  //   // }

  //   return setQuery(query(collection, ...conditions, ...conditionPage));
  // }, [sorting, pagination, collection, conditions]);

  // useEffect(() => {
  //   getCountFromServer(query(collection, ...conditions))
  //     .then(snapshot => {
  //       setCount(snapshot.data().count);
  //     })
  //     .catch(err => {
  //       console.error(err);
  //       console.error(err.code);
  //       setCount(undefined);
  //     });
  // }, [conditions, collection]);

  // Optionally handle loading and error states
  return { table, loading, error };
};

export default useFirestoreTable;
