import classNames from 'classnames';
import React, { useState } from 'react';
import { Nav } from 'react-bootstrap';

export interface FilterTabItem {
  label: string;
  value: string;
  onClick?: () => void;
  count: number;
}

interface FilterTabProps {
  tabItems: FilterTabItem[];
  className?: string;

  defaultActiveItem?: string;
  onClickItem?: (item: FilterTabItem) => void;
}

const FilterTab = ({
  tabItems,
  className,
  onClickItem,
  defaultActiveItem = 'all'
}: FilterTabProps) => {
  const [activeItem, setActiveItem] = useState(defaultActiveItem);

  const handleClick = (item: FilterTabItem) => {
    setActiveItem(item.value);
    if (item.onClick) {
      item.onClick();
    }
    if (onClickItem) onClickItem(item);
  };

  return (
    <Nav className={classNames(className, 'nav nav-links mx-n2')}>
      {tabItems.map(item => (
        <Nav.Item key={item.label}>
          <Nav.Link
            onClick={() => handleClick(item)}
            className={classNames('px-2', {
              active: activeItem === item.value
            })}
          >
            {item.label}{' '}
            <span className="text-700 fw-semi-bold">({item.count})</span>
          </Nav.Link>
        </Nav.Item>
      ))}
    </Nav>
  );
};

export default FilterTab;
