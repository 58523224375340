import SignInLinkValidationComponent from 'components/modules/auth/SignInLinkValidation';
import AuthSimpleLayout from 'layouts/AuthSimpleLayout';

const SignInLinkValidation = () => {
  return (
    <AuthSimpleLayout>
      <SignInLinkValidationComponent layout="simple" />
    </AuthSimpleLayout>
  );
};

export default SignInLinkValidation;
