import { httpsCallable } from 'firebase/functions';
import { FUNCTIONS } from 'providers/FirebaseProvider';
import { ReactNode, createContext } from 'react';
// @types
//

// ----------------------------------------------------------------------

export type APIContextType = {
  createStripePortalLink: () => Promise<string>;
};

const APIContext = createContext<APIContextType | null>(null);

// ----------------------------------------------------------------------

type APIProviderProps = {
  children: ReactNode;
};

function APIProvider({ children }: APIProviderProps) {
  const createStripePortalLink = async (): Promise<string> => {
    const request = httpsCallable<unknown, any>(
      FUNCTIONS,
      'ext-firestore-stripe-payments-createPortalLink'
    );

    const { data } = await request({
      returnUrl: window.location.origin,
      locale: 'auto' // Optional, defaults to "auto"
    });

    return data.url;
  };

  return (
    <APIContext.Provider
      value={{
        createStripePortalLink
      }}
    >
      {children}
    </APIContext.Provider>
  );
}

export { APIContext, APIProvider };
