import { APIContext } from 'providers/APIProvider';
import { useContext } from 'react';
//

// ----------------------------------------------------------------------

const useAPI = () => {
  const context = useContext(APIContext);

  if (!context) throw new Error('API context must be use inside APIContext');

  return context;
};

export default useAPI;
