import useAuth from 'hooks/useAuth';
import { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';

const GuestGuard = ({ children }: PropsWithChildren) => {
  const { isInitialized, isAuthenticated } = useAuth();

  if (isAuthenticated) {
    // Redirect to login page
    return <Navigate to="/" replace />;
  }

  return <>{isInitialized && !isAuthenticated ? children : null}</>;
};

export default GuestGuard;
