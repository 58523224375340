import { Table } from '@tanstack/react-table';
import { PropsWithChildren, createContext, useContext } from 'react';

export const FirebaseTableContext = createContext({});

interface FirebaseTable<T> {
  table: Table<T>;
  loading: boolean;
}

const FirebaseTableProvider = ({ children, ...rest }: PropsWithChildren) => {
  return (
    <FirebaseTableContext.Provider value={{ ...rest }}>
      {children}
    </FirebaseTableContext.Provider>
  );
};

export const useFirestoreTableContext = <T,>() =>
  useContext(FirebaseTableContext) as FirebaseTable<T>;

export default FirebaseTableProvider;
