// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@xtract.humpact.fr.

// ----------------------------------------------------------------------

const fr = {
  // action: {},
  firebase: {
    'auth/email-already-in-use': 'Un compte est déja associé à cet email.',
    'auth/quota-exceeded':
      'Vous avez dépassé le nombre de demande. Merci de patienter quelques minutes'
  }
};

export default fr;
