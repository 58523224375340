import { useEffect, useState } from 'react';
import { Route, RouteItems, routes } from 'sitemap';
import useAuth from './useAuth';
import { useCdiscountCategorySnapshots } from './useFirestore';
import { UilShoppingBag } from '@iconscout/react-unicons';

function filterRoutes(routeItems: RouteItems[], role?: string): RouteItems[] {
  return routeItems
    .filter(item => !item.roles || (role && item.roles.includes(role)))
    .map(item => ({
      ...item,
      pages: item.pages ? filterPages(item.pages, role) : []
    }));
}

function filterPages(routes: Route[], role?: string): Route[] {
  return routes
    .filter(route => !route.roles || (role && route.roles.includes(role)))
    .map(route => ({
      ...route,
      pages: route.pages ? filterPages(route.pages, role) : []
    }));
}

export const useNavigationRoutes = (): RouteItems[] => {
  const { user } = useAuth();
  const authorization = user?.authorization;

  const [items, setItems] = useState<RouteItems[]>([]);
  const [allCategories] = useCdiscountCategorySnapshots();

  useEffect(() => {
    const staticRoutes = filterRoutes(routes, user?.role);
    const cdiscount: RouteItems = {
      label: 'Cdiscount',
      icon: UilShoppingBag,
      pages: !allCategories
        ? []
        : allCategories?.docs
            .filter(c => c.data()?.Parent === null)
            .map(cat => {
              const data = cat.data();
              // If you want to add subcategories -> Then add it
              // const childs = allCategories?.docs
              //   .filter(child => child.data()?.Parent?.id === cat.id)
              //   .map(child => {
              //     const data = child.data();
              //     return {
              //       id: child.id,
              //       ...data,
              //       name: data.Category || 'unknown',
              //       active: true,
              //       icon: 'circle',
              //       path: `/krakken/cdiscount/${data.slug}`,
              //       pathName: `krakken-cdiscount-${data.slug}`
              //     };
              //   });

              return {
                id: cat.id,
                ref: cat.ref,
                ...data,
                name: data.Category || 'unknown',
                // pages: childs,
                active: authorization === 'granted',

                //
                icon: authorization === 'granted' ? 'circle' : 'lock',
                path: `/krakken/cdiscount/${data.slug}`,
                pathName: `krakken-cdiscount-${data.slug}`
              };
            })
    };
    setItems([...(allCategories?.size ? [cdiscount] : []), ...staticRoutes]);
  }, [user, allCategories]);

  return items;
};
