export type ThemeVariant = 'light' | 'dark';
export type NavPositionVariant = 'horizontal' | 'vertical' | 'combo' | 'dual';
export type NavTopShapeVariant = 'default' | 'slim';
export type NavbarAppearanceVariant = 'default' | 'darker';
export interface Config {
  isNavbarVerticalCollapsed: boolean;
  openNavbarVertical: boolean;
  theme: ThemeVariant;
  navbarVerticalAppearance: NavbarAppearanceVariant;
  navbarTopAppearance: NavbarAppearanceVariant;
  navbarPosition: NavPositionVariant;
  navbarTopShape: NavTopShapeVariant;
  isRTL: boolean;
  bodyClass?: string;
  isChatWidgetVisible: boolean;
}

export const initialConfig: Config = {
  isNavbarVerticalCollapsed: false,
  openNavbarVertical: false, // for responsive
  theme: 'light',
  navbarTopAppearance: 'default',
  navbarVerticalAppearance: 'default',
  navbarPosition: 'vertical',
  navbarTopShape: 'default',
  isRTL: false,
  isChatWidgetVisible: false
};

// Formation Marketplace
// ----------------------------------------------------------------------
export const FORMATION_URL =
  'https://www.frenchstartupper.co/marketplace-hotlist';

// API
// ----------------------------------------------------------------------

export const ENVIRONMENT = process.env.NODE_ENV;

export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};
// export const FIREBASE_EMULATOR = {
//   scheme: process.env.REACT_APP_EMULATOR_EMULATOR_SCHEME,
//   host: process.env.REACT_APP_EMULATOR_EMULATOR_HOST!,
//   authPort: Number(process.env.REACT_APP_EMULATOR_AUTH_PORT),
//   firestorePort: Number(process.env.REACT_APP_EMULATOR_FIRESTOR_PORT),
//   functionsPort: Number(process.env.REACT_APP_EMULATOR_FUNCTIONS_PORT),
//   storagePort: Number(process.env.REACT_APP_EMULATOR_STORAGE_PORT)
// };
export const FIREBASE_EMULATOR: any = undefined;

// MULTI LANGUAGES
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'French',
    value: 'fr'
    // systemValue: frFR
    // icon: '/assets/icons/flags/ic_flag_fr.svg'
  },
  {
    label: 'English',
    value: 'en'
    // systemValue: enUS
    // icon: '/assets/icons/flags/ic_flag_en.svg',
  }
];

export const defaultLang = allLangs[0]; // English
