import AuthSimpleLayout from 'layouts/AuthSimpleLayout';
import useAuth from 'hooks/useAuth';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { FORMATION_URL } from 'config';

const Authorization = () => {
  const { user, logout } = useAuth();

  return (
    <AuthSimpleLayout>
      <>
        <div className="text-center mb-7">
          <h3 className="text-1000">
            {(!user?.authorization || user?.authorization === 'checking') &&
              'Nous vérifions vos informations...'}
            {user?.authorization === 'granted' && 'Félicitations !'}
            {user?.authorization === 'not-student' && 'Oh non ...'}
            {user?.authorization === 'revoked' && 'Oups ...'}
          </h3>
        </div>
        <div className="text-center">
          {(!user?.authorization || user?.authorization === 'checking') && (
            <Spinner animation="grow" />
          )}
          {user?.authorization === 'not-student' && (
            <div>
              <p>
                Malheureusement, cette application est réservée pour les
                étudiants de la formation Marketplace pour les aider à trouver
                des produits gagnants !
              </p>
              <a href={FORMATION_URL} target="_blank" rel="noreferrer">
                Voir la formation <FontAwesomeIcon icon={faExternalLink} />
              </a>
              <p className="text-500 mt-2">
                Vérifie que ton email ({user.email}) correspond bien à celui
                utilisé lors de l'achat de la formation.
                <br />
                Si c'est une erreur, ouvre un ticket sur le Discord de la
                formation.
              </p>
            </div>
          )}
          {user?.authorization === 'revoked' && (
            <div>
              <p>
                Malheureusement, ton accès a été supprimé... Probablement parce
                que tu as quitté la formation ou tu ne fais plus partie de la
                communauté des vendeurs Marketplace.
              </p>
              <p className="text-500 mt-2">
                Si c'est une erreur, ouvre un ticket sur le Discord de la
                formation.
              </p>
            </div>
          )}
          {user?.authorization &&
            ['granted', 'limited'].includes(user?.authorization) && (
              <div>
                <p>Ton compte est validé.</p>
                <p className="text-500 mt-2">
                  Tu peux dès à présent commencer à utiliser l'application.
                </p>
              </div>
            )}
        </div>
        <div className="px-3 mt-7">
          {(!user?.authorization ||
            !['granted', 'limited'].includes(user?.authorization)) && (
            <Link
              onClick={logout}
              to="#!"
              className="btn btn-krakken-danger d-flex flex-center w-100"
            >
              Se déconnecter
              <FeatherIcon icon="log-out" className="me-2" size={16} />
            </Link>
          )}
          {user?.authorization &&
            ['granted', 'limited'].includes(user?.authorization) && (
              <Link
                to="/krakken"
                className="btn btn-krakken-success d-flex flex-center w-100"
              >
                Accéder à {process.env.REACT_APP_NAME}
                <FeatherIcon icon="corner-right-up" />
              </Link>
            )}
        </div>
      </>
    </AuthSimpleLayout>
  );
};

export default Authorization;
