import { useCallback, useEffect, useState } from 'react';
import {
  DocumentData,
  FirestoreError,
  Query,
  getCountFromServer
} from 'firebase/firestore';
import { useDebounce, useMountedState } from 'react-use';
import { FirebaseError } from 'firebase/app';

// ----------------------------------------------------------------------

export type CollectionCountOnceHook = [
  number | undefined,
  boolean,
  FirebaseError | undefined,
  () => Promise<void>
];

export const useCollectionCountOnce = <T extends DocumentData>(
  query?: Query<T> | null
): CollectionCountOnceHook => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<FirebaseError>();
  const [count, setCount] = useState<number>();
  const isMounted = useMountedState();

  const loadData = useCallback(
    async (query: Query<T> | null) => {
      if (!query) {
        setCount(undefined);
        return;
      }

      setLoading(true);
      try {
        const result = await getCountFromServer(query);
        if (isMounted()) {
          setCount(result?.data()?.count);
          setError(undefined);
        }
      } catch (error) {
        console.error(error);
        if (isMounted()) {
          setCount(undefined);
          setError(error as FirestoreError);
        }
      }
      setLoading(false);
    },
    [setCount, setError, isMounted]
  ); // Added missing dependencies

  useDebounce(() => loadData(query || null), 500, [query]);

  const reloadData = useCallback(
    () => loadData(query || null),
    [loadData, query]
  );

  useEffect(() => {
    loadData(query || null);
  }, [query, loadData]); // Added missing dependency

  return [count, loading, error, reloadData];
};

export default useCollectionCountOnce;
