import {
  DocumentData,
  DocumentReference,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SetOptions,
  SnapshotOptions,
  WithFieldValue,
  addDoc,
  collection,
  doc,
  limit,
  orderBy,
  query,
  setDoc,
  where
} from 'firebase/firestore';
import _ from 'lodash';
import { DB } from 'providers/FirebaseProvider';
import {
  useCollectionDataOnce,
  useCollectionOnce,
  useDocumentData
} from 'react-firebase-hooks/firestore';
import {
  CheckoutSession,
  CreateCheckoutSession
} from '../../types/firestore/checkout';
// ----------------------------------------------------------------------

// Define a generic interface that your types should conform to.

export type DocumentDataIdentifiable<T> = T & {
  id: string;
  ref: DocumentReference<T>;
};

// A generic Firestore data converter
export function createFirestoreDataConverter<T>() {
  const converter: FirestoreDataConverter<DocumentDataIdentifiable<T>> = {
    toFirestore(
      modelObject: WithFieldValue<DocumentDataIdentifiable<T>>
    ): DocumentData {
      const data = _.omit(modelObject, ['id', 'ref']); // Exclude fields not to store
      return data as DocumentData;
    },
    fromFirestore(
      snapshot: QueryDocumentSnapshot,
      options: SnapshotOptions
    ): DocumentDataIdentifiable<T> {
      const data = snapshot.data(options);
      return {
        ...(data as Omit<T, 'id' | 'ref'>),
        id: snapshot.id,
        ref: snapshot.ref
      } as DocumentDataIdentifiable<T>;
    }
  };
  return converter;
}

// ----------------------------------------------------------------------

export const useCdiscountParentCategories = () => {
  const categoriesRef = collection(DB, 'Categories');
  const q = query(
    categoriesRef,
    where('Marketplace', '==', 'Cdiscount'),
    where('Parent', '==', null),
    orderBy('Category')
  );
  return useCollectionDataOnce(q);
};
export const useCdiscountCategorySnapshots = () => {
  const categoriesRef = collection(DB, 'Categories');
  const q = query(
    categoriesRef,
    where('Category', '!=', null),
    where('Marketplace', '==', 'Cdiscount'),
    where('enabled', '==', true),
    orderBy('Category')
  );
  return useCollectionOnce(q);
};

export const useCategoriesBySlug = (slug: string) => {
  const categoriesRef = collection(DB, 'Categories');
  const q = query(
    categoriesRef,
    where('slug', '==', slug),
    orderBy('Category'),
    limit(1)
  );
  return useCollectionOnce(q);
};

export const useUpdateProduct =
  () =>
  (productId: string, data: object, options: SetOptions = {}) => {
    return setDoc(doc(collection(DB, 'Products'), productId), data, options);
  };

// ----------------------------------------------------------------------

export const usePricingPlans = () => {
  const pricingRefs = collection(DB, 'Pricing Plans');
  const q = query(pricingRefs, where('active', '==', true));
  return useCollectionOnce(q);
};

export const usePricingsData = (planId: string | undefined) => {
  let q = null;
  if (planId) {
    const pricingRefs = collection(DB, 'Pricing Plans', planId, 'prices');
    q = query(pricingRefs, where('active', '==', true));
  }
  return useCollectionDataOnce(q);
};
export const usePricings = (planId: string | undefined) => {
  let q = null;
  if (planId) {
    const pricingRefs = collection(DB, 'Pricing Plans', planId, 'prices');
    q = query(pricingRefs, where('active', '==', true));
  }
  return useCollectionOnce(q);
};

// ----------------------------------------------------------------------
export const useCreate = () => {
  return { createCheckoutSession };
};

// ----------------------------------------------------------------------

const createCheckoutSession = async (
  userId: string,
  create: CreateCheckoutSession
): Promise<DocumentReference<CheckoutSession>> => {
  console.debug('---- ', userId, create, {
    allow_promotion_codes: create.allow_promotion_codes || true,
    ...create
  });
  const docRef: DocumentReference<CheckoutSession> = (await addDoc(
    collection(DB, 'Users', userId, 'checkout_sessions'),
    {
      allow_promotion_codes: create.allow_promotion_codes || true,
      ...create
    }
  )) as DocumentReference<CheckoutSession>;

  return docRef;
};

export const useCheckoutSession = (
  uid: string | undefined,
  sessionId: string | undefined
) => {
  let ref = null;
  if (uid && sessionId) {
    ref = doc(collection(DB, 'Users', uid, 'checkout_sessions'), sessionId);
  }
  return useDocumentData(ref);
};
