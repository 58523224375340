import useAuth from 'hooks/useAuth';
import React, { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';

interface AuthGuardProps extends PropsWithChildren {
  disableCheckingAuthorization?: boolean;
}
const AuthGuard = ({
  children,
  disableCheckingAuthorization
}: AuthGuardProps) => {
  const { isInitialized, isAuthenticated, user } = useAuth();

  if (isInitialized) {
    if (!isAuthenticated) return <Navigate to="/auth/sign-in" />;
    else if (
      // user?.authorization === 'checking' &&
      user?.authorization !== 'granted' &&
      !disableCheckingAuthorization
    )
      return <Navigate to="/onboard/authorization" />;
  }

  return <>{isInitialized && isAuthenticated && children}</>;
};

export default AuthGuard;
