import { faSquareCheck, faSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
// import FilterButtonGroup, {
//   FilterMenu
// } from 'components/common/FilterButtonGroup';
import FilterTab, { FilterTabItem } from 'components/common/FilterTab';
// import PageBreadcrumb from 'components/common/PageBreadcrumb';
import ProductsTable, {
  productsTablecolumns
} from 'components/tables/KrakkenProductsTable';
import { FirebaseError } from 'firebase/app';
// import { defaultBreadcrumbItems } from 'data/commonData';
import { DocumentData, collection, where } from 'firebase/firestore';
import useAuth from 'hooks/useAuth';
import { useCategoriesBySlug, useUpdateProduct } from 'hooks/useFirestore';
import useFirestoreTable from 'hooks/useFirestoreTable';
import useToaster from 'hooks/useToaster';
import { DB } from 'providers/FirebaseProvider';
import FirebaseTableProvider from 'providers/FirebaseTableProvider';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const defaultTabItems: FilterTabItem[] = [
  {
    label: 'Activé',
    value: 'enabled',
    count: 0
  },
  {
    label: 'Désactivé',
    value: 'disabled',
    count: 0
  },
  {
    label: 'À déterminer',
    value: 'to_determine',
    count: 0
  }
];

// const filterMenus: FilterMenu[] = [
//   {
//     label: 'Category',
//     items: [
//       {
//         label: 'Plants'
//       },
//       {
//         label: 'Furniture'
//       },
//       {
//         label: 'Fashion'
//       }
//     ]
//   },
//   {
//     label: 'Vendor',
//     items: [
//       {
//         label: 'Blue Olive Plant sellers. Inc'
//       },
//       {
//         label: 'Beatrice Furnitures'
//       },
//       {
//         label: 'Kizzstore'
//       }
//     ]
//   }
// ];

interface Filters {
  search?: string;
  enabled?: unknown;
}
const KrakkenProductsPage = () => {
  const params = useParams();
  const { isAdmin } = useAuth();

  // methods
  const updateProduct = useUpdateProduct();
  const toaster = useToaster();

  const [conditions, setConditions] = useState<any[]>([]);
  const [categories] = useCategoriesBySlug(params.category || '');
  const [category, setCategory] = useState<DocumentData>();
  const [tabItems, setTabItems] = useState(defaultTabItems);
  const [filters, setFilters] = useState<Filters>({ enabled: true });

  // Set selected category
  useEffect(() => {
    const data = categories?.docs.at(0)?.data();
    const id = categories?.docs.at(0)?.id;
    const ref = categories?.docs.at(0)?.ref;
    setCategory(id ? { ...data, id, ref } : undefined);
  }, [categories]);

  // Update firestore query
  useEffect(() => {
    if (category) {
      const conditions = [
        where('Category', '==', category.ref),
        where('Title', '!=', '')
      ];

      if (isAdmin && filters?.enabled !== undefined)
        conditions.push(where('enabled', '==', filters.enabled));
      else if (!isAdmin) {
        conditions.push(where('enabled', '==', true));
        conditions.push(where('Price', '>=', 1000));
      }

      setConditions(conditions);
    } else {
      setConditions([]);
    }
  }, [category, filters]);

  useEffect(() => {
    if (category) {
      const all = category.metadata?.counts?.all || 0;
      const enabled = category.metadata?.counts?.enabled || 0;
      const disabled = category.metadata?.counts?.disabled || 0;
      const rest = all - enabled - disabled;
      setTabItems([
        { ...defaultTabItems[0], count: enabled },
        { ...defaultTabItems[1], count: disabled },
        { ...defaultTabItems[2], count: rest }
      ]);
    } else {
      setTabItems(defaultTabItems);
    }
  }, [category]);

  const table = useFirestoreTable({
    columns: productsTablecolumns,
    sortable: true,
    selection: isAdmin,
    pagination: true,
    pageSize: 60,
    initialState: {
      sorting: [{ id: 'Title', desc: false }]
    },
    firestoreQuery: {
      collection: collection(DB, 'Products'),
      conditions
    }
  });
  // const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
  //   console.debug(e);
  //   setFilters({ ...filters, search: e.target.value || undefined });
  // };

  const handleClickFilter = (item: FilterTabItem) => {
    setFilters({
      ...filters,
      enabled: [
        ['enabled', true],
        ['disabled', false],
        ['to_determine', null]
      ]
        .find(v => v[0] === item.value)
        ?.at(1)
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleClickUpdateSelected = (enabled: boolean) => async () => {
    const rows = table.table.getSelectedRowModel().rows;

    if (rows.length < 1) {
      toaster.info('Aucun produit sélectionné');
      return;
    }
    for (const row of rows) {
      try {
        await updateProduct(row.original.id, { enabled }, { merge: true });
      } catch (error: any) {
        if (error instanceof FirebaseError) toaster.error(error.code);
        else toaster.error(error.message);
      }
    }

    toaster.success('Produits mis à jour');
    table.table.setRowSelection({});
  };

  return (
    <div>
      {/* <PageBreadcrumb items={defaultBreadcrumbItems} /> */}
      <div className="mb-9">
        <h2>Produits Cdiscount {category?.Category}</h2>
        {category?.metadata?.counts?.enabled && (
          <p>{JSON.stringify(category?.metadata?.counts?.enabled)} produits</p>
        )}
        {table && (
          <>
            {isAdmin && (
              <FilterTab
                defaultActiveItem="enabled"
                tabItems={tabItems}
                className="mb-2"
                onClickItem={handleClickFilter}
              />
            )}
            <FirebaseTableProvider {...table}>
              <div className="mb-4">
                <div className="d-flex flex-wrap gap-3">
                  {/* <SearchBox
                    placeholder="Search products"
                    onChange={handleSearchInputChange}
                  /> */}
                  {/* <div className="scrollbar overflow-hidden-y">
                    <FilterButtonGroup menus={filterMenus} />
                  </div> */}
                  <div className="me-auto"></div>
                  {isAdmin && (
                    <div className="ms-xxl-auto">
                      <Button
                        disabled={
                          table.table.getSelectedRowModel().rows.length <= 0
                        }
                        onClick={handleClickUpdateSelected(true)}
                        startIcon={<FontAwesomeIcon icon={faSquareCheck} />}
                        variant="krakken-success"
                        className="me-4"
                      >
                        Activer
                      </Button>
                      <Button
                        disabled={
                          table.table.getSelectedRowModel().rows.length <= 0
                        }
                        onClick={handleClickUpdateSelected(false)}
                        variant="krakken-danger"
                        startIcon={<FontAwesomeIcon icon={faSquare} />}
                        className="me-4"
                      >
                        Désactiver
                      </Button>
                    </div>
                  )}
                </div>
              </div>

              <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
                <ProductsTable />
              </div>
            </FirebaseTableProvider>
          </>
        )}
      </div>
    </div>
  );
};

export default KrakkenProductsPage;
