import { initializeApp } from 'firebase/app';
import * as FirebaseAuth from 'firebase/auth';
import {
  DocumentData,
  collection,
  connectFirestoreEmulator,
  doc,
  getDoc,
  getFirestore,
  setDoc
} from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { connectStorageEmulator, getStorage } from 'firebase/storage';
import { createContext, ReactNode, useEffect, useReducer } from 'react';
import * as analytics from 'firebase/analytics';
// @types
import {
  ActionMap,
  AuthState,
  AuthUser,
  FirebaseContextType
} from '../../types/auth';
//
import { ENVIRONMENT, FIREBASE_API, FIREBASE_EMULATOR } from '../config';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { useLogger } from 'react-use';

// ----------------------------------------------------------------------

export const firebaseApp = initializeApp(FIREBASE_API);

const AUTH = FirebaseAuth.getAuth(firebaseApp);

export const FUNCTIONS = getFunctions(firebaseApp, 'europe-west1');
export const DB = getFirestore(firebaseApp);
export const STORAGE = getStorage(firebaseApp);
let ANALYTICS: analytics.Analytics;

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  isAdmin: false,
  user: null,
  profile: null
};

enum Types {
  Initial = 'INITIALISE',
  SetProfile = 'SET_PROFILE'
}

type FirebaseAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
    profile?: DocumentData | undefined;
  };
  [Types.SetProfile]: {
    profile?: DocumentData | undefined;
  };
};

type FirebaseActions =
  ActionMap<FirebaseAuthPayload>[keyof ActionMap<FirebaseAuthPayload>];

const reducer = (state: AuthState, action: FirebaseActions) => {
  if (action.type === 'INITIALISE') {
    const { isAuthenticated, user, profile } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      profile: profile || null
    };
  } else if (action.type === 'SET_PROFILE') {
    const { profile } = action.payload;
    return {
      ...state,
      profile: profile || null
    };
  }

  return state;
};

const AuthContext = createContext<FirebaseContextType | null>(null);

// ----------------------------------------------------------------------
// Emulator connection
if (ENVIRONMENT !== 'production' && FIREBASE_EMULATOR) {
  FirebaseAuth.connectAuthEmulator(
    AUTH,
    `${FIREBASE_EMULATOR.scheme}://${FIREBASE_EMULATOR.host}:${FIREBASE_EMULATOR.authPort}`
  );
  FirebaseAuth.connectAuthEmulator(
    AUTH,
    `${FIREBASE_EMULATOR.scheme}://${FIREBASE_EMULATOR.host}:${FIREBASE_EMULATOR.authPort}`
  );
  connectFirestoreEmulator(
    DB,
    FIREBASE_EMULATOR.host,
    FIREBASE_EMULATOR.firestorePort
  );
  connectFunctionsEmulator(
    FUNCTIONS,
    FIREBASE_EMULATOR.host,
    FIREBASE_EMULATOR.functionsPort
  );
  connectStorageEmulator(
    STORAGE,
    FIREBASE_EMULATOR.host,
    FIREBASE_EMULATOR.storagePort
  );

  if (typeof window !== 'undefined') {
    ANALYTICS = analytics.getAnalytics(firebaseApp);
  }
}
// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);
  useLogger('User', state);

  // To keep profile updated
  const [liveProfile] = useDocumentData(
    state.user?.uid ? doc(collection(DB, 'Users'), state.user?.uid) : undefined
  );

  useEffect(() => {
    dispatch({
      type: Types.SetProfile,
      payload: { profile: liveProfile }
    });
  }, [liveProfile]);

  useEffect(() => {
    if (state.user?.id && ANALYTICS)
      analytics.setUserId(ANALYTICS, state.user?.id);
  }, [state.user?.id]);

  useEffect(
    () =>
      FirebaseAuth.onAuthStateChanged(AUTH, async user => {
        let profile;

        if (user) {
          const userRef = doc(DB, 'Users', user.uid);
          const docSnap = await getDoc(userRef);

          if (docSnap.exists()) {
            profile = docSnap.data();
          }
        }
        dispatch({
          type: Types.Initial,
          payload: { isAuthenticated: Boolean(user), user, profile }
        });
      }),
    [dispatch]
  );

  // Token expiration
  useEffect(() => {
    const exp = liveProfile?.expirationToken?.toDate();
    if (exp instanceof Date) {
      if (exp < new Date()) {
        // Token has expired then refresh
        console.debug(`Token has expired the ${exp}`);
        AUTH.currentUser
          ?.getIdToken(true)
          .then(v => {
            console.info('Token is refreshed', v);
            const userRef = doc(collection(DB, 'Users'), state.user?.uid);
            setDoc(userRef, { expirationToken: null }, { merge: true });
          })
          .catch(err => console.error('Failed to refresh the token', err));
      }
    }
  }, [liveProfile?.expirationToken]);

  const signInWithEmailAndPassword = (email: string, password: string) =>
    FirebaseAuth.signInWithEmailAndPassword(AUTH, email, password);

  const sendSignInLinkToEmail = (email: string, url?: string) => {
    const { protocol, hostname, port } = window.location;
    const baseUrl = port
      ? `${protocol}//${hostname}:${port}`
      : `${protocol}//${hostname}`;

    return FirebaseAuth.sendSignInLinkToEmail(AUTH, email, {
      url: url ? url : `${baseUrl}/auth/sign-in-link-validation`,
      handleCodeInApp: true
    });
  };

  const signInWithEmailLink = (email: string, url: string) =>
    FirebaseAuth.signInWithEmailLink(AUTH, email, url);

  const isSignInWithEmailLink = () =>
    FirebaseAuth.isSignInWithEmailLink(AUTH, window.location.href);

  const register = (
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ) =>
    FirebaseAuth.createUserWithEmailAndPassword(AUTH, email, password).then(
      async res => {
        const userRef = doc(collection(DB, 'Users'), res.user?.uid);

        await setDoc(userRef, {
          uid: res.user?.uid,
          email,
          displayName: `${firstName} ${lastName}`
        });
      }
    );

  const logout = () => {
    console.debug('Loging out');
    return FirebaseAuth.signOut(AUTH);
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'firebase',
        isInitialized: state.isInitialized,
        isAdmin: state?.profile?.role === 'admin',
        user: {
          id: state?.user?.uid,
          email: state?.user?.email,
          authorization: state.profile?.authorization,
          photoURL: state?.user?.photoURL || state.profile?.photoURL,
          displayName:
            [state?.user?.firstname, state?.user?.lastname].join(' ').trim() ||
            state?.user?.displayName ||
            state?.user?.email,
          role: state.profile?.role || 'not-found',
          phoneNumber:
            state?.user?.phoneNumber || state.profile?.phoneNumber || '',
          country: state.profile?.country || '',
          address: state.profile?.address || '',
          state: state.profile?.state || '',
          city: state.profile?.city || '',
          zipCode: state.profile?.zipCode || '',
          about: state.profile?.about || '',
          isPublic: state.profile?.isPublic || false
        },
        sendSignInLinkToEmail,
        signInWithEmailAndPassword,
        signInWithEmailLink,
        isSignInWithEmailLink,
        register,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
