import DefaultLandingNavbar from 'components/navbars/default-landing-navbar/DefaultLandingNavbar';
import useSettingsMountEffect from 'hooks/useSettingsMountEffect';

const PrivacyPolicyPage = () => {
  useSettingsMountEffect({
    disableNavigationType: true,
    disableHorizontalNavbarAppearance: true,
    disableVerticalNavbarAppearance: true,
    disableHorizontalNavbarShape: true
  });

  return (
    <div className="bg-white">
      <DefaultLandingNavbar />
      <section className="container-small px-lg-7 px-xxl-3">
        <h1>Politique de confidentialité</h1>
        <p>
          Cette politique de confidentialité s'applique uniquement à nos
          activités en ligne et est valable pour les utilisateurs de notre
          application en ce qui concerne les informations qu'ils partagent et/ou
          collectées dans Krakken. Cette politique ne concerne pas les
          informations collectées hors ligne ou via d'autres canaux que notre
          application.
        </p>

        <h2 className="fs-6">SECTION 1 : CONSENTEMENT</h2>
        <p>
          En utilisant notre application, vous acceptez par la présente notre
          politique de confidentialité et vous en acceptez les conditions.
        </p>

        <h2 className="fs-6">
          SECTION 2 : HÉBERGEMENT DU SITE ET STOCKAGE DES DONNÉES
        </h2>
        <p>
          Notre application est hébergée sur une plateforme sécurisée. Toutes
          vos données sont stockées de manière sécurisée et protégées par des
          sécurité appropriées.
        </p>

        <h2 className="fs-6">SECTION 3 : INFORMATIONS QUE NOUS RECUEILLONS</h2>
        <p>
          Nous ne collectons aucune information personnelle directement auprès
          des utilisateurs de notre application.
        </p>

        <h2 className="fs-6">
          SECTION 4 : COMMENT NOUS UTILISONS VOS INFORMATIONS
        </h2>
        <p>
          Étant donné que nous ne collectons aucune information personnelle des
          utilisateurs, nous n'utilisons pas ces informations.
        </p>

        <h2 className="fs-6">SECTION 5 : COOKIES ET BALISES WEB</h2>
        <p>Notre application n'utilise pas de cookies ou de balises web.</p>

        <h2 className="fs-6">SECTION 6 : PAIEMENT</h2>
        <p>
          Notre application ne collecte pas d'informations de paiement, car elle
          ne propose pas de fonctionnalités de paiement.
        </p>

        <h2 className="fs-6">SECTION 7 : DIVULGATION</h2>
        <p>
          Nous ne divulguons aucune information personnelle des utilisateurs.
        </p>

        <h2 className="fs-6">SECTION 8 : LIENS ET REDIRECTIONS</h2>
        <p>
          Notre application peut contenir des liens vers des sites tiers. Nous
          ne sommes pas responsables des pratiques de confidentialité de ces
          sites tiers.
        </p>

        <h2 className="fs-6">
          SECTION 9 : POLITIQUES DE CONFIDENTIALITÉ DES PARTENAIRES
          PUBLICITAIRES
        </h2>
        <p>
          Notre application n'affiche pas de publicités et ne travaille pas avec
          des partenaires publicitaires.
        </p>

        <h2 className="fs-6">
          SECTION 10 : POLITIQUES DE CONFIDENTIALITÉ DES TIERS
        </h2>
        <p>
          Les politiques de confidentialité des tiers ne s'appliquent pas à
          notre application car nous ne partageons pas les informations des
          utilisateurs avec des tiers.
        </p>

        <h2 className="fs-6">SECTION 11 : DROITS DE PROTECTION DES DONNÉES</h2>
        <p>
          Étant donné que nous ne collectons pas d'informations personnelles des
          utilisateurs, les droits de protection des données de la RGPD ne
          s'appliquent pas à notre application.
        </p>

        <h2 className="fs-6">SECTION 12 : INFORMATIONS SUR LES ENFANTS</h2>
        <p>
          Notre application n'est pas destinée aux enfants de moins de 13 ans et
          nous ne collectons pas sciemment d'informations personnelles auprès de
          ces enfants.
        </p>

        <h2 className="fs-6">SECTION 13 : NOUS CONTACTER</h2>
        <p>
          Si vous avez des questions concernant notre politique de
          confidentialité, veuillez nous contacter à l'adresse suivante :
          contact@{window.location.hostname}.
        </p>

        <h2 className="fs-6">
          SECTION 14 : Comment Assurons-Nous la Sécurité de Vos Informations
        </h2>
        <p>
          Nous employons des mesures de sécurité techniques et
          organisationnelles pour garantir la transmission sécurisée des
          données, même si nous ne collectons pas d'informations personnelles
          des utilisateurs.
        </p>

        <p>
          Nous espérons que cette politique de confidentialité vous apporte les
          informations nécessaires concernant la manière dont nous traitons les
          données des utilisateurs de l'application Krakken. Si vous avez des
          questions supplémentaires, n'hésitez pas à nous contacter.
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicyPage;
