import { FormProvider as Form, UseFormReturn } from 'react-hook-form';
import { Form as BForm, FormProps as BFormProps } from 'react-bootstrap';

// ----------------------------------------------------------------------

interface Props extends BFormProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  methods: UseFormReturn<any>;
}

export default function FormProvider({ children, methods, ...props }: Props) {
  return (
    <Form {...methods}>
      <BForm {...props}>{children}</BForm>
    </Form>
  );
}
